import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Matches from "./views/Matches";
import Auth from "./views/Auth";
import Users from "./views/Users";
import Stat from "./views/Stat";
import Files from "./views/Files";
import Scout from "./views/Scout";
import Text from "./views/Text";
import Stream from "./views/Stream";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Matches/>}/>
                <Route path="/auth" element={<Auth/>}/>
                <Route path="/users" element={<Users/>}/>
                <Route path="/stat" element={<Stat/>}/>
                <Route path="/files" element={<Files/>}/>
                <Route path="/scout" element={<Scout />}/>
                <Route path="/text" element={<Text />}/>
                <Route path="/stream" element={<Stream />}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
