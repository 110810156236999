import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import closeIcon from "../svg/close.svg";

import {
    changePrice,
    editMatch,
    getMatches,
    getViews,
    matchEvent,
    newMatch, newText,
    setComment,
    setScore,
    setStatus,
    newScout, editPriceMatch
} from "../api";
import Menu from "./Components/Menu";
import PrimaryButton from "./Components/PrimaryButton";

const Matches = () => {
    const nav = useNavigate();
    const [matches, setMatches] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [price, setPrice] = useState();
    const [views, setViews] = useState(0);
    const [curEvent, setCurEvent] = useState('')

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }

        async function fetchData() {
            const _matches = await getMatches(localStorage.getItem("admin-token"));
            localStorage.setItem("admin-role", _matches.data.userRole)
            setMatches(_matches.data.matches);
            setPrice(_matches.data.price)
        }

        fetchData();
    }, []);


    useEffect(() => {
        const interv = setInterval(async () => {
            if (modalData.id) {
                const view = await getViews(
                    localStorage.getItem("admin-token"),
                    modalData.id
                );
                setViews(view.data);
            }
        }, 1000);

        return () => clearInterval(interv);
    }, [modalData]);
    return (
        <>
            <div class="matches">
                <Menu />
                <section className={"ms-5"}>
                    <div className={"flex justify-between mb-3 mt-3 text-white py-5 px-3 rounded-md bg-gray-800"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Матчи</h1>
                    </div>
                    <ul>
                        {matches.map((item) => (
                            <li
                                onClick={() => {
                                    setModalData({
                                        ...item,
                                        comment: "",
                                    });
                                }}
                            >
                                {item.name} ({item.type}) {localStorage.getItem("admin-role") == "superAdmin" ? `(${item.connectedUsers ? item.connectedUsers : 0} подключений, ${item.minutesView ? Math.floor(item.minutesView) : 0} минут прослушано)` : `${item.connectedUsers ? 'Есть слушатели' : 'Нет слушателей'}`} Цена: {item.price}
                            </li>
                        ))}
                        <div
                            className="button"
                            onClick={async () => {
                                const name = prompt("Введите название матча");
                                if (name && name !== null) {
                                    const data = await newMatch(
                                        localStorage.getItem("admin-token"),
                                        name,
                                        "football"
                                    );
                                    setMatches(data.data.matches);
                                }
                            }}
                        >
                            Новый матч (футбол)
                        </div>
                        <br/>
                        <div
                            className="button"
                            onClick={async () => {
                                const name = prompt("Введите название матча");
                                if (name && name !== null) {
                                    const data = await newMatch(
                                        localStorage.getItem("admin-token"),
                                        name,
                                        "basketball"
                                    );
                                    setMatches(data.data.matches);
                                }
                            }}
                        >
                            Новый матч (баскетбол)
                        </div>
                    </ul>
                    <div>
                        <h2>Цена за минуту</h2>
                        <ol>
                            <li>
                                <input
                                    type="text"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </li>
                            <li>
                                <div
                                    onClick={async () => {
                                        const data = await changePrice(
                                            localStorage.getItem("admin-token"),
                                            price
                                        );
                                        if (data.status == "success") {
                                            alert("Успешно");
                                        }
                                    }}
                                >
                                    Сохранить
                                </div>
                            </li>
                        </ol>
                    </div>

                </section>
            </div>

            {modalData.status === 0 || modalData.status === 3 ? (
                <div class="nameofmatch">
                    <div className={"text-white"}>
                        <h1 className={"cursor-pointer"} onClick={async () => {
                            const name = prompt("Введите название матча");
                            if (name && name !== null) {
                                const data = await editMatch(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    name
                                );
                                setMatches(data.data.matches);
                                setModalData({})
                            }
                        }}>{modalData.name}</h1> Цена: <span className={"cursor-pointer"}

                        onClick={async () => {
                            const price = prompt("Введите новую цену матча");
                            if (price && price !== null) {
                                const data = await editPriceMatch(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    price
                                );
                                setMatches(data.data.matches);
                                setModalData({})
                            }
                        }}
                    >{modalData.price}</span>
                        <img src={closeIcon} onClick={() => setModalData({})} alt="close"/>
                        <br/>
                        <br/>
                        <div
                            onClick={async () => {
                                const data = await setStatus(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    1
                                );
                                setMatches(data.data.matches);
                                setModalData({});
                            }}
                        >
                            Запустить
                        </div>
                        <br/>
                        <div
                            onClick={async () => {
                                const data = await setStatus(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    3
                                );
                                setMatches(data.data.matches);
                                setModalData({});
                            }}
                        >
                            Удалить
                        </div>


                    </div>
                </div>
            ) : (
                <></>
            )}
            {modalData.status === 1 || modalData.status === 2 ? (
                <div class="nameofmatch2">
                    <div>
                        <h1 onClick={async () => {
                            const name = prompt("Введите название матча");
                            if (name && name !== null) {
                                const data = await editMatch(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    name
                                );
                                setMatches(data.data.matches);
                                setModalData({})
                            }
                        }}>{modalData.name}</h1>
                        <img src={closeIcon} onClick={() => setModalData({})} alt="close"/>

                        <div class="comment">
                            <span>Смотрит: </span>
                            {views} человек
                        </div>
                        {modalData.type == "football" && (<>
                            <div>
                                <ol>
                                    <li>
                                        <input
                                            type="number"
                                            placeholder="Счет команды"
                                            value={modalData?.score?.team1}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    score: {
                                                        ...modalData.score,
                                                        team1: e.currentTarget.value,
                                                    },
                                                });
                                                setScore(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    e.currentTarget.value,
                                                    modalData.score.team2
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1"
                                            className={curEvent == 'b1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('b1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "ball"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Опасно"
                                            className={curEvent == 'd1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('d1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "danger"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Гол"
                                            className={curEvent == 'g1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('g1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "goal"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Угловой"
                                            className={curEvent == 'u1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('u1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "corner"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Красная карточка"
                                            className={curEvent == 'r1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('r1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "redCard"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Желтая карточка"
                                            className={curEvent == 'y1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('y1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "yellowCard"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Штрафной удар"
                                            className={curEvent == 'sh1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('sh1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "freeKick"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="1 Пенальти"
                                            className={curEvent == 'p1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('p1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "penalty"
                                                );
                                            }}
                                        />
                                    </li>
                                </ol>
                                <ul>
                                    <li>
                                        <input
                                            type="number"
                                            placeholder="Счет команды"
                                            value={modalData?.score?.team2}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    score: {
                                                        ...modalData.score,
                                                        team2: e.currentTarget.value,
                                                    },
                                                });
                                                setScore(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    modalData.score.team1,
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2"
                                            className={curEvent == 'b2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('b2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "ball"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Опасно"
                                            className={curEvent == 'd2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('d2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "danger"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Гол"
                                            className={curEvent == 'g2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('g2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "goal"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Угловой"
                                            className={curEvent == 'u2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('u2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "corner"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Красная карточка"
                                            className={curEvent == 'r2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('r2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "redCard"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Желтая карточка"
                                            className={curEvent == 'y2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('y2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "yellowCard"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Штрафной удар"
                                            className={curEvent == 'sh2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('sh2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "freeKick"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="2 Пенальти"
                                            className={curEvent == 'p2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('p2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "penalty"
                                                );
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </>)}
                        {modalData.type == "basketball" && (<>
                            <div>
                                <ol>
                                    <li>
                                        <input
                                            type="number"
                                            placeholder="Счет команды"
                                            value={modalData?.score?.team1}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    score: {
                                                        ...modalData.score,
                                                        team1: e.currentTarget.value,
                                                    },
                                                });
                                                setScore(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    e.currentTarget.value,
                                                    modalData.score.team2
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="+1 первый"
                                            className={curEvent == 'bp1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('bp1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "plusOne"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="+2 первый"
                                            className={curEvent == 'bpp1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('bpp1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "plusTwo"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="+3 первый "
                                            className={curEvent == 'bppp1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('bppp1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "plusThree"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="Фол"
                                            className={curEvent == 'f1' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('f1')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "fall"
                                                );
                                            }}
                                        />
                                    </li>
                                </ol>
                                <ul>
                                    <li>
                                        <input
                                            type="number"
                                            placeholder="Счет команды"
                                            value={modalData?.score?.team2}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    score: {
                                                        ...modalData.score,
                                                        team2: e.currentTarget.value,
                                                    },
                                                });
                                                setScore(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    modalData.score.team1,
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="+1 второй"
                                            className={curEvent == 'bp2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('bp2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "plusOne"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="+2 второй"
                                            className={curEvent == 'bpp2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('bpp2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "plusTwo"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="+3 второй"
                                            className={curEvent == 'bppp2' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('bppp2')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    2,
                                                    "plusThree"
                                                );
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="button"
                                            value="Остановка"
                                            className={curEvent == 'ost' ? 'active' : ''}
                                            onClick={async () => {
                                                setCurEvent('ost')
                                                await matchEvent(
                                                    localStorage.getItem("admin-token"),
                                                    modalData.id,
                                                    1,
                                                    "stop"
                                                );
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </>)}
                        <textarea
                            value={modalData.comment}
                            onChange={(e) => {
                                setComment(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    e.target.value
                                );
                                setModalData({
                                    ...modalData,
                                    comment: e.target.value,
                                });
                            }}
                        />
                        {modalData.status == 1 && (
                            <button
                                onClick={async () => {
                                    const data = await setStatus(
                                        localStorage.getItem("admin-token"),
                                        modalData.id,
                                        2
                                    );
                                    setMatches(data.data.matches);
                                    setModalData({});
                                }}
                            >
                                Перерыв
                            </button>
                        )}

                        {modalData.status == 2 && (
                            <button
                                onClick={async () => {
                                    const data = await setStatus(
                                        localStorage.getItem("admin-token"),
                                        modalData.id,
                                        1
                                    );
                                    setMatches(data.data.matches);
                                    setModalData({});
                                }}
                            >
                                Возобновить
                            </button>
                        )}
                        <button
                            onClick={async () => {
                                const data = await setStatus(
                                    localStorage.getItem("admin-token"),
                                    modalData.id,
                                    3
                                );
                                setMatches(data.data.matches);
                                setModalData({});
                            }}
                        >
                            Завершить
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Matches;
