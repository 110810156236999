import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    getScout,
    delScout,
    newText,
    newScout,
    updateScout,
    addCatScout,
    getCats,
    delScoutCat,
    editScoutCat
} from "../api";
import Menu from "./Components/Menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "./Components/PrimaryButton";
import { useFormik } from 'formik';
import InputLabel from "./Components/InputLabel";
import SecondaryButton from "./Components/SecondaryButton";
import Modal from "./Components/Modal";

const Texts = () => {
    const nav = useNavigate();
    const [showAddModal, setShowAddModal] = useState(false)
    const [data, setData] = useState([]);
    const [cats, setCats] = useState([]);
    const formik = useFormik({
        initialValues: {
            name: '',
            price: '',
            cat_id: null,
            token: localStorage.getItem("admin-token")
        },
        onSubmit: async (values) => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            await newScout(formData)
            await fetchData()
            setShowAddModal(false)
            formik.resetForm();
        }
    });
    const handleFileUpload = (event) => {
        formik.setFieldValue("file", event.currentTarget.files[0])
    }
    async function fetchData() {
        const _data = await getScout(localStorage.getItem("admin-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("admin-token");
            nav("/auth");
        }
        setCats(_data.data.scoutsCat);
        setData(_data.data.scout);
    }

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }

        fetchData();
    }, []);

    const deleteScout = async (id) => {
        const _files = await delScout(localStorage.getItem("admin-token"), id);
        setCats(_files.data.scoutsCat);
        setData(_files.data.scout);
    }
    const deleteScoutCat = async (id) => {
        await delScoutCat(localStorage.getItem("admin-token"), id);
        fetchData();
    }

    const closeModal = () => {
        setShowAddModal(false);
        formik.resetForm();
    };
    const editScout = async (id, name, price) => {
        formik.setFieldValue("name", name)
        formik.setFieldValue("price", price)
        formik.setFieldValue("id", id)
        setShowAddModal(true);
    }

    const setCatEdit = async (id) => {
        formik.setFieldValue("cat_id", id)
    }

    const addScout = async () => {
        const name = prompt("Введите название");
        const price = prompt("Введите цену");
        const text = prompt("Введите ссылку на файл");
        if (name && name !== null && text && text !== null) {
            const data = await newScout(
                localStorage.getItem("admin-token"),
                name,
                price,
                text
            );
            alert("Успешно")
        }
    }
    console.log(data)
    return (
        <>
            <div class="users">
                <Menu />
                <section className={"min-w-[1000px] ms-5"}>
                    <div className={"flex justify-between mb-10 mt-3 text-white py-5 px-3 rounded-md bg-gray-800"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Scout</h1>
                        <div>
                            <PrimaryButton onClick={async () => {
                                const name = prompt("Введите название");
                                if (name && name !== null) {
                                    const _cat = await addCatScout(
                                        localStorage.getItem("admin-token"),
                                        name
                                    );
                                    setCats(_cat.data);
                                    alert("Успешно")
                                }
                            }}>Добавить категорию</PrimaryButton>
                        </div>
                    </div>

                    <div className="mb-3">
                        {cats.length > 0 && cats.map((cat) => {
                            return (
                                <div className={"py-5 px-3 rounded-md bg-gray-800 mb-5"}>
                                    <div
                                        className={"flex justify-between  text-white px-3 mb-5"}>
                                        <div className={"flex items-center"}>
                                            <h1 className={"text-4xl font-extrabold leading-none me-3"}>{cat.name}</h1>
                                            <FontAwesomeIcon icon={faPenToSquare} onClick={async () => {
                                                const name = prompt("Введите название", cat.name);
                                                if (name && name !== null) {
                                                    const _cat = await editScoutCat(
                                                        localStorage.getItem("admin-token"),
                                                        name,
                                                        cat._id
                                                    );
                                                    fetchData()
                                                    alert("Успешно")
                                                }
                                            }} className={"ms-3 text-green-600 cursor-pointer"}/>
                                            <FontAwesomeIcon icon={faTrash} onClick={() => {
                                                if (window.confirm('Are you sure you wish to delete this item?'))
                                                    deleteScoutCat(cat._id)
                                            }} className={"ms-3 text-red cursor-pointer"}/>
                                        </div>


                                        <div>
                                            <PrimaryButton onClick={async () => {
                                                await setCatEdit(cat._id)
                                                setShowAddModal(true)
                                            }}>Добавить</PrimaryButton>
                                        </div>
                                    </div>

                                    <div className={"grid grid-cols-4 gap-4 mb-3 py-5 px-3"}>

                                        {data.length > 0 && data
                                            .filter((scout) => scout._doc.cat_id == cat._id)
                                            .map((item) => {
                                            return (
                                                <div className=" text-white p-3 bg-gray-700 rounded-md">
                                                    <div className="flex justify-between ">
                                                        <h1>{item._doc.name}</h1>
                                                        <div>
                                                            <FontAwesomeIcon icon={faPenToSquare} onClick={() => {
                                                                editScout(item._doc._id, item._doc.name, item._doc.price)
                                                            }} className={"ms-3 text-green-600 cursor-pointer"}/>
                                                            <FontAwesomeIcon icon={faTrash} onClick={() => {
                                                                if (window.confirm('Are you sure you wish to delete this item?'))
                                                                    deleteScout(item._doc._id)
                                                            }} className={"ms-3 text-red cursor-pointer"}/>
                                                        </div>
                                                    </div>

                                                    <div className={"cursor-pointer text-center my-5"} onClick={() => {
                                                        window.open(`${item._doc.text}`)
                                                    }}>ФАЙЛ
                                                    </div>

                                                    <div
                                                        className={"grid justify-items-end"}>Цена: {item._doc.price}</div>

                                                    <div className={"flex justify-end mt-5"}>Продаж сегодня: <span
                                                        className={"text-green-600 ms-1"}>{item.sellToday}</span></div>
                                                    <div className={"flex justify-end"}>Продаж 7 дней: <span
                                                        className={"text-green-600 ms-1"}>{item.sellSevenDays}</span>
                                                    </div>

                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>

                            );
                        })}
                    </div>

                </section>

                <Modal show={showAddModal} onClose={closeModal}>
                    <form onSubmit={formik.handleSubmit} className="p-6">
                        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                            Добавить/редактировать Scout
                        </h2>

                        <div className="mt-6">
                            <InputLabel htmlFor="name" value="Название"/>

                            <input
                                id="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                required
                                autoComplete="name"
                                className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 '}
                            />
                        </div>

                        <div className="mt-6">
                            <InputLabel htmlFor="price" value="Цена"/>
                            <input
                                id="price"
                                onChange={formik.handleChange}
                                value={formik.values.price}
                                required
                                autoComplete="price"
                                className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'}
                            />
                        </div>

                        <div className="mt-6">
                            <InputLabel htmlFor="file" value="Загрузить файл"/>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                id="file" name="file" type="file" onChange={handleFileUpload}/>
                        </div>

                        <div className="mt-6 flex justify-end">
                            <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>

                            <PrimaryButton type="submit" className="ms-3">
                                Сохранить
                            </PrimaryButton>
                        </div>
                    </form>
                </Modal>

            </div>
        </>
    );
};

export default Texts;
