import React from 'react';
import {useNavigate, useLocation} from "react-router-dom";

const Menu = () => {
    const nav = useNavigate();
    const location = useLocation();

    return (
        <nav className={"flex flex-col justify-between"}>
            <ul>
                <li className={location.pathname == '/' ? "active" : "notActive"} onClick={() => nav("/")}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                            fill="#90AFFF"
                        />
                        <mask
                            id="mask0_9_5276"
                            style={{"mask-type": "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="3"
                            width="20"
                            height="18"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_9_5276)">
                            <rect width="24" height="24" fill="transparent"/>
                        </g>
                    </svg>
                    <span>Матчи</span>
                </li>

                {localStorage.getItem("admin-role") == "superAdmin" && (
                    <>
                        <li className={location.pathname == '/users' ? "active" : "notActive"}
                            onClick={() => nav("/users")}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                    fill="#231F20"
                                />
                                <mask
                                    id="mask0_9_5286"
                                    maskUnits="userSpaceOnUse"
                                    x="3"
                                    y="2"
                                    width="18"
                                    height="19"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0_9_5286)">
                                    <rect width="24" height="24" fill="white"/>
                                </g>
                            </svg>
                            <span>Пользователи</span>
                        </li>
                        <li className={location.pathname == '/stat' ? "active" : "notActive"}
                            onClick={() => nav("/stat")}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                    fill="#231F20"
                                />
                                <mask
                                    id="mask0_9_5286"
                                    maskUnits="userSpaceOnUse"
                                    x="3"
                                    y="2"
                                    width="18"
                                    height="19"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0_9_5286)">
                                    <rect width="24" height="24" fill="white"/>
                                </g>
                            </svg>
                            <span>Статистика</span>
                        </li>

                        <li className={location.pathname == '/files' ? "active" : "notActive"}
                            onClick={() => nav("/files")}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                    fill="#231F20"
                                />
                                <mask
                                    id="mask0_9_5286"
                                    maskUnits="userSpaceOnUse"
                                    x="3"
                                    y="2"
                                    width="18"
                                    height="19"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0_9_5286)">
                                    <rect width="24" height="24" fill="white"/>
                                </g>
                            </svg>
                            <span>Файлы</span>
                        </li>


                        <li className={location.pathname == '/text' ? "active" : "notActive"}
                            onClick={() => nav("/text")}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                    fill="#231F20"
                                />
                                <mask
                                    id="mask0_9_5286"
                                    maskUnits="userSpaceOnUse"
                                    x="3"
                                    y="2"
                                    width="18"
                                    height="19"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0_9_5286)">
                                    <rect width="24" height="24" fill="white"/>
                                </g>
                            </svg>
                            <span>Bookmakers</span>
                        </li>
                        </>
                )}
                {(localStorage.getItem("admin-role") == "superAdmin" || localStorage.getItem("admin-role") == "MOD" ) && (
                    <>
                        <li className={location.pathname == '/scout' ? "active" : "notActive"}
                            onClick={() => nav("/scout")}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                    fill="#231F20"
                                />
                                <mask
                                    id="mask0_9_5286"
                                    maskUnits="userSpaceOnUse"
                                    x="3"
                                    y="2"
                                    width="18"
                                    height="19"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0_9_5286)">
                                    <rect width="24" height="24" fill="white"/>
                                </g>
                            </svg>
                            <span>Scouts</span>
                        </li>

                        <li className={location.pathname == '/stream' ? "active" : "notActive"}
                            onClick={() => nav("/stream")}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                    fill="#231F20"
                                />
                                <mask
                                    id="mask0_9_5286"
                                    maskUnits="userSpaceOnUse"
                                    x="3"
                                    y="2"
                                    width="18"
                                    height="19"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0_9_5286)">
                                    <rect width="24" height="24" fill="white"/>
                                </g>
                            </svg>
                            <span>Stream</span>
                        </li>
                    </>
                )}

            </ul>
            <ul>
                <li className={"notActive cursor-pointer mt-5"} onClick={() => {
                    localStorage.removeItem("client-token")
                    localStorage.removeItem("admin-token")
                    localStorage.removeItem("admin-role")
                    nav("/auth");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"/>
                    </svg>
                    <span>Logout</span>
                </li>
            </ul>
        </nav>
    );
};

export default Menu;