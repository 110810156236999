import {useEffect, useState} from "react";
import {checkToken} from "../api";
import {useNavigate} from "react-router-dom";

const Auth = () => {
    const nav = useNavigate();
    const [input, setInput] = useState("");

    useEffect(() => {
        if (localStorage.getItem("admin-token")) {
            nav("/");
        }
    }, []);

    const handleClick = async () => {
        const check = await checkToken(input);
        if (check.status == "success") {
            localStorage.setItem("admin-token", input);
            nav("/");
        } else {
            alert("Error");
            window.location.reload();
        }
    };

    return (
        <div class="auth">
            <input
                type="text"
                placeholder="Введите ключ"
                onChange={(e) => setInput(e.target.value)}
                value={input}
            />
            <div onClick={handleClick}>Продолжить</div>
        </div>
    );
};

export default Auth;
