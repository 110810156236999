import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Menu from "./Components/Menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "./Components/PrimaryButton";
import { useFormik } from 'formik';
import InputLabel from "./Components/InputLabel";
import SecondaryButton from "./Components/SecondaryButton";
import Modal from "./Components/Modal";
import { getStream, newStream } from "../api";

const Stream = () => {
    const nav = useNavigate();
    const [showAddModal, setShowAddModal] = useState(false)
    const [server, setServer] = useState('rtmp://79.137.198.162:1935/live');
    const [streamKey, setStreamKey] = useState('obs_stream');
    const formik = useFormik({
        initialValues: {
            price: 1,
            token: localStorage.getItem("admin-token")
        },
        onSubmit: async (values) => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            await newStream(formData)
        }
    });

    async function fetchData() {
        const _data = await getStream(localStorage.getItem("admin-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("admin-token");
            nav("/auth");
        }
        formik.setFieldValue("price", _data.data.stream?.price || 0)
    }

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }
        fetchData();
    }, []);

    const closeModal = () => {
        setShowAddModal(false);
    };
    const handleFocus = (event) => event.target.select();
    return (
        <>
            <div class="users">
                <Menu />
                <section className={"min-w-[1000px] ms-5"}>
                    <div className={"flex justify-between mb-10 mt-3 text-white py-5 px-3 rounded-md bg-gray-800"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Stream</h1>
                    </div>

                    <div className="mb-3">
                    <div className={"py-5 px-3 rounded-md bg-gray-800 mb-5"}>
                                <div className={"flex justify-between  text-white px-3 mb-5"}>
                                        <div className={"flex items-center"}>
                                        <form onSubmit={formik.handleSubmit} className="p-6">
                                            <h2 className="text-lg font-medium text-gray-100">
                                                Цена за просмотр
                                            </h2>
                                            <div className="mt-6">
                                                <InputLabel htmlFor="price" value="Цена"/>
                                                <input
                                                    id="price"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.price}
                                                    required
                                                    autoComplete="price"
                                                    className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'}
                                                />
                                            </div>

                                            <div className="mt-6 flex justify-end">
                                                <PrimaryButton type="submit" className="ms-3">
                                                    Сохранить
                                                </PrimaryButton>
                                            </div>
                                        </form>
                                        </div>


                                        <div>
                                            <PrimaryButton onClick={() => {
                                                setShowAddModal(true)
                                            }}>Настройки OBS</PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                    </div>

                </section>

                <Modal show={showAddModal} onClose={closeModal}>
                    <div className="p-6">
                        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                            Настройки для OBS Studio
                        </h2>

                        <div className="mt-6">
                            <InputLabel htmlFor="server" value="Сервер" className="!text-gray-900"/>

                            <input
                                id="server"
                                value={server}
                                readOnly
                                onFocus={handleFocus}
                                autoComplete="server"
                                className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 '}
                            />
                        </div>

                        <div className="mt-6">
                            <InputLabel htmlFor="streamKey" value="Ключ потока" className="!text-gray-900"/>

                            <input
                                id="streamKey"
                                value={streamKey}
                                readOnly
                                onFocus={handleFocus}
                                autoComplete="streamKey"
                                className={'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 '}
                            />
                        </div>

                        <div className="mt-6 flex justify-end">
                            <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    );
};

export default Stream;
