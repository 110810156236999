import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import closeIcon from "../svg/close.svg";

import {
    addDays,
    changeBalance,
    deleteUser,
    getMatches, getStat,
    getUsers,
    newUser,
    clearStat
} from "../api";
import Menu from "./Components/Menu";
import PrimaryButton from "./Components/PrimaryButton";

const Stat = () => {
    const nav = useNavigate();
    const [matches, setMatches] = useState([]);
    const [stat, setStat] = useState([]);

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }

        async function fetchData() {
            const _matches = await getMatches(localStorage.getItem("admin-token"));
            setMatches(_matches.data);

            const _stat = await getStat(localStorage.getItem("admin-token"));
            setStat(_stat.data);
        }

        fetchData();
    }, []);

    return (
        <>
            <div class="users">
                <Menu />
                <section className={"ms-5"}>
                    <div className={"flex justify-between mb-3 mt-3 text-white py-5 px-3 bg-gray-800 rounded-md"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Статистика</h1>
                    </div>
                    <div className={"bg-gray-800 rounded-md"}>
                        <table>
                            <thead>
                            <tr>
                                <th>Параметр</th>
                                <th>Значение</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Минут прослушано (все время)</td>
                                <td>{Math.floor(matches.allMinutesView)}</td>
                            </tr>
                            <tr>
                                <td>Всего подключений (все время)</td>
                                <td>{matches.allUsers}</td>
                            </tr>
                            <br/>
                            <tr>
                                <td>Минут прослушано (24 часа)</td>
                                <td>{Math.floor(matches.dayView)}</td>
                            </tr>
                            <tr>
                                <td>Всего подключений (24 часа)</td>
                                <td>{matches.dayUsers}</td>
                            </tr>
                            <br/>
                            <tr>
                                <td>Минут прослушано (7 дней)</td>
                                <td>{Math.floor(matches.sevenDaysView)}</td>
                            </tr>
                            <tr>
                                <td>Всего подключений (7 дней)</td>
                                <td>{matches.sevenDaysUsers}</td>
                            </tr>
                            <tr>
                                <td colspan="2"><br/><br/><br/><br/></td>
                            </tr>


                            <tr>
                                <td>Всего продаж сегодня</td>
                                <td>{stat.dayAllBuys ? stat.dayAllBuys : 0} на сумму {stat.dayAllBuysPrice ? stat.dayAllBuysPrice : 0}</td>
                            </tr>
                            <tr>
                                <td>Всего продаж (7 дней)</td>
                                <td>{stat.sevenDaysAllBuys ? stat.sevenDaysAllBuys : 0} на сумму {stat.sevenDaysAllBuysPrice ? stat.sevenDaysAllBuysPrice : 0}</td>
                            </tr>

                            <tr>
                                <td>Всего продаж books сегодня</td>
                                <td>{stat.dayBuysText ? stat.dayBuysText : 0} на сумму {stat.dayAllPriceText ? stat.dayAllPriceText : 0}</td>
                            </tr>
                            <tr>
                                <td>Всего продаж books (7 дней)</td>
                                <td>{stat.sevenDaysBuysText ? stat.sevenDaysBuysText : 0} на сумму {stat.sevenDaysAllPriceText ? stat.sevenDaysAllPriceText : 0}</td>
                            </tr>


                            <tr>
                                <td>Всего продаж scout сегодня</td>
                                <td>{stat.dayBuysScout ? stat.dayBuysScout : 0} на сумму {stat.dayAllPriceScout ? stat.dayAllPriceScout : 0}</td>
                            </tr>
                            <tr>
                                <td>Всего продаж scout (7 дней)</td>
                                <td>{stat.sevenDaysBuysScout ? stat.sevenDaysBuysScout : 0} на сумму {stat.sevenDaysAllPriceScout ? stat.sevenDaysAllPriceScout : 0}</td>
                            </tr>

                            </tbody>
                        </table>


                        <div className={"mt-5 ms-5 mb-5"}>
                            <PrimaryButton onClick={async () => {
                                if (window.confirm('Are you sure you wish to clear stat?'))
                                await clearStat(localStorage.getItem("admin-token"))
                                let _stat = await getStat(localStorage.getItem("admin-token"))
                                setStat(_stat.data);
                            }}>Сбросить статистику</PrimaryButton>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Stat;
