import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getFiles, newUser, sendMail, delFiles} from "../api";
import Menu from "./Components/Menu";

import closeIcon from "../svg/close.svg";
import showIcon from "../svg/layers.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "./Components/PrimaryButton";

const Files = () => {
    const nav = useNavigate();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }

        async function fetchData() {
            const _files = await getFiles(localStorage.getItem("admin-token"));
            setFiles(_files.data.files);
        }

        fetchData();
    }, []);

    const delFile = async (file) => {
        const _files = await delFiles(localStorage.getItem("admin-token"), file);
        setFiles(_files.data.files);
    }

    return (
        <>
            <div class="users">
                <Menu/>
                <section className={"ms-5"}>
                    <div className={"flex justify-between mb-3 mt-3 text-white py-5 px-3 bg-gray-800 rounded-md"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Файлы</h1>
                    </div>
                    <div className={"bg-gray-800 rounded-md"}>
                        <table>
                            <thead>
                            <tr>
                                <th>Файл</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {files.map((item) => {
                                return (
                                    <tr>
                                        <td>{item}</td>
                                        <td>
                                            <FontAwesomeIcon icon={faEye} onClick={() => window.open(`https://api.noscout.pw/api/client/uploads/${item}`)} />
                                            <FontAwesomeIcon icon={faTrash} onClick={() => {
                                                if (window.confirm('Are you sure you wish to delete this item?'))
                                                    delFile(item)
                                            }} className={"ms-3 text-red"} />
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Files;