const ENDPOINT = "https://api.sportmarket.club"

export const getStream = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/stream/get?token=${token}`)
    const res = await req.json()
    return res
}
export const newStream = async (formData) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/stream/update`), {
        method: "POST",
        body: formData
    })
    const res = await req.json()
    return res
}


export const checkToken = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/checkToken?token=${token}`, { mode: 'cors', headers: { 'Content-Type': 'application/json'}})
    const res = await req.json()

    return res
}

export const changePrice = async (token, newPrice) => {
    const req = await fetch(`${ENDPOINT}/api/admin/changePrice?token=${token}&newPrice=${newPrice}`)
    const res = await req.json()

    return res
}

export const getMatches = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/matches/get?token=${token}`)
    const res = await req.json()

    return res
}
export const getStat = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/stat/get?token=${token}`)
    const res = await req.json()

    return res
}
export const clearStat = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/stat/clear?token=${token}`)
    const res = await req.json()

    return res
}
export const setStatus = async (token, match, status) => {
    const req = await fetch(`${ENDPOINT}/api/admin/matches/status?token=${token}&match=${match}&status=${status}`)
    const res = await req.json()

    return res
}

export const newMatch = async (token, name, type) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/new?token=${token}&name=${name}&type=${type}`))
    const res = await req.json()

    return res
}

export const matchEvent = async (token, match, team, event) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/event?token=${token}&team=${team}&match=${match}&event=${event}`))
    const res = await req.json()

    return res
}

export const getUsers = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/get?token=${token}`)
    const res = await req.json()

    return res
}

export const newUser = async (token, days, tags) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/new?token=${token}&days=${days}&tags=${tags}`)
    const res = await req.json()

    return res
}

export const newModer = async (token, days, notify) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/newmod?token=${token}`)
    const res = await req.json()

    return res
}
export const banUser = async (token, id) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/ban?token=${token}&id=${id}`)
    const res = await req.json()

    return res
}
export const updateTag = async (token, id, tag) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/tag?token=${token}&id=${id}&tag=${tag}`)
    const res = await req.json()

    return res
}
export const changeBalance = async (token, userToken, newBalance) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/balance?token=${token}&userToken=${userToken}&newBalance=${newBalance}`)
    const res = await req.json()

    return res
}

export const addDays = async (token, userToken, days) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/days?token=${token}&userToken=${userToken}&days=${days}`)
    const res = await req.json()

    return res
}

export const deleteUser = async (token, userToken) => {
    const req = await fetch(`${ENDPOINT}/api/admin/users/delete?token=${token}&userToken=${userToken}`)
    const res = await req.json()

    return res
}

export const setScore = async (token, match, team1, team2) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/score?token=${token}&team1=${team1}&match=${match}&team2=${team2}`))
    const res = await req.json()

    return res
}

export const setComment = async (token, match, comment) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/comment?token=${token}&match=${match}&comment=${comment}`))
    const res = await req.json()

    return res
}

export const getViews = async (token, match) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/views?token=${token}&match=${match}`))
    const res = await req.json()

    return res
}

export const editMatch = async (token, match, name) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/edit?token=${token}&match=${match}&name=${name}`))
    const res = await req.json()

    return res
}
export const editPriceMatch = async (token, match, price) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/matches/updatePrice?token=${token}&match=${match}&price=${price}`))
    const res = await req.json()

    return res
}

export const sendMail = async (token, text, ids=null) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/mail/send`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            text,
            ids
        })
    })
    const res = await req.json()

    return res
}


export const newText = async (token, name, price, text, text2) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/texts/new`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            text,
            text2,
            price,
            name,
        })
    })
    const res = await req.json()

    return res
}

export const newScout = async (formData) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/scout/new`), {
        method: "POST",
/*        headers: {
            "content-type": "application/json"
        },*/
        body: formData
    })
    const res = await req.json()

    return res
}

export const getScout = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/scout/get?token=${token}`)
    const res = await req.json()
    return res
}
export const getCats = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/scout/cats/get?token=${token}`)
    const res = await req.json()
    return res
}
export const getText = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/text/get?token=${token}`)
    const res = await req.json()
    return res
}

export const getFiles = async (token) => {
    const req = await fetch(`${ENDPOINT}/api/admin/files/get?token=${token}`)
    const res = await req.json()

    return res
}

export const delFiles = async (token, file) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/files/del`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            file
        })
    })
    const res = await req.json()

    return res
}

export const delScout = async (token, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/scout/del`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id
        })
    })
    const res = await req.json()

    return res
}
export const editScoutCat = async (token, name, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/scout/cat/edit`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id,
            name
        })
    })
    const res = await req.json()

    return res
}
export const delScoutCat = async (token, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/scout/cat/del`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id
        })
    })
    const res = await req.json()

    return res
}
export const delText = async (token, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/text/del`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id
        })
    })
    const res = await req.json()

    return res
}

export const startText = async (token, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/text/start`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id
        })
    })
    const res = await req.json()

    return res
}

export const pauseText = async (token, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/text/pause`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            id
        })
    })
    const res = await req.json()

    return res
}

export const addCatScout = async (token, name) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/scout/cat/add`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            name
        })
    })
    const res = await req.json()

    return res
}
export const updateScout = async (token, name, price, text, text2, id) => {
    const req = await fetch(decodeURI(`${ENDPOINT}/api/admin/text/update`), {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            token,
            name,
            price,
            text,
            text2,
            id
        })
    })
    const res = await req.json()

    return res
}