import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {getScout, delText, getText, updateScout, newText, startText, pauseText} from "../api";
import Menu from "./Components/Menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faCirclePause, faCirclePlay} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "./Components/PrimaryButton";

const Texts = () => {
    const nav = useNavigate();
    const [data, setData] = useState({
        texts: [],
    });

    async function fetchData() {
        const _data = await getText(localStorage.getItem("admin-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("admin-token");
            nav("/auth");
        }
        setData(_data.data);
    }

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }

        fetchData();
    }, []);

    const deleteScout = async (id) => {
        const _files = await delText(localStorage.getItem("admin-token"), id);
        setData(_files.data);
    }

    const startSell = async (id) => {
        const _files = await startText(localStorage.getItem("admin-token"), id);
        setData(_files.data);
    }

    const pauseSell = async (id) => {
        const _files = await pauseText(localStorage.getItem("admin-token"), id);
        setData(_files.data);
    }

    const addText = async () => {
        const name = prompt("Введите название");
        const price = prompt("Введите цену");
        const text = prompt("Введите текст");
        const text2 = prompt("Введите текст красным");
        if (name && name !== null) {
            const data = await newText(
                localStorage.getItem("admin-token"),
                name,
                price,
                text,
                text2
            );
            fetchData();
            alert("Успешно")
        }
    }

    const editText = async (id, nameText, priceText, content, text2content) => {
        const name = prompt("Введите название", nameText);
        const price = prompt("Введите цену", priceText);
        const text = prompt("Введите текст", content);
        const text2 = prompt("Введите текст красным", text2content);
        if (name && name !== null && text && text !== null ) {
            const _texts = await updateScout(
                localStorage.getItem("admin-token"),
                name,
                price,
                text,
                text2,
                id
            );
            setData(_texts.data);
            alert("Успешно")
        }
    }
    return (
        <>
            <div class="users">
                <Menu />
                <section className={"ms-5"}>
                    <div className={"flex justify-between mb-3 mt-3 text-white py-5 px-3 rounded-md bg-gray-800"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Bookmakers</h1>
                        <PrimaryButton onClick={addText}>Добавить</PrimaryButton>
                    </div>


                    <div className={"bg-gray-800 rounded-md"}>
                        <table>
                            <thead>
                            <tr>
                                <th>Название</th>
                                <th>Цена</th>
                                <th>Текст</th>
                                <th>Sell сегодня</th>
                                <th>Sell 7д</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.texts.length > 0 && data.texts.map((item) => {
                                console.log(item)
                                    return (
                                        <tr>
                                            <td className={"cursor-pointer"} onClick={() => {
                                                editText(item._doc._id,item._doc.name,item._doc.price,item._doc.text,item._doc.text2)
                                            }}>{item._doc.name}</td>
                                            <td>{item._doc.price}</td>
                                            <td>{item._doc.text}</td>

                                            <td>{item.sellToday}</td>
                                            <td>{item.sellSevenDays}</td>

                                            <td>
                                                <FontAwesomeIcon icon={faTrash} onClick={() => {
                                                    if (window.confirm('Are you sure you wish to delete this item?'))
                                                        deleteScout(item._doc._id)
                                                }} className={"ms-3 text-red cursor-pointer"} />
                                            </td>

                                            
                                            <td>
                                                {item._doc.status == 1 ? (

                                                <FontAwesomeIcon icon={faCirclePause} onClick={() => {
                                                    if (window.confirm('Are you sure you wish to PAUSE SELL?'))
                                                        pauseSell(item._doc._id)
                                                }} className={"ms-3 text-yellow-500 cursor-pointer"} />
                                                ) : (

                                                    <FontAwesomeIcon icon={faCirclePlay} onClick={() => {
                                                        if (window.confirm('Are you sure you wish to START SELL?'))
                                                            startSell(item._doc._id)
                                                    }} className={"ms-3 text-green-500 cursor-pointer"} />
                                                )
                                                }
                                            </td>
                                        </tr>
                                    );
                            })}
                            </tbody>
                        </table>
                    </div>
                    </section>
            </div>
        </>
);
};

export default Texts;
