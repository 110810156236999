import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import closeIcon from "../svg/close.svg";

import {addDays, changeBalance, deleteUser, getUsers, newUser, sendMail, newModer, banUser, updateTag} from "../api";
import Menu from "./Components/Menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCircleCheck} from "@fortawesome/free-solid-svg-icons";

const Users = () => {
    const nav = useNavigate();
    const [users, setUsers] = useState([]);
    const [modalUser, setModalUser] = useState({});

    useEffect(() => {
        if (!localStorage.getItem("admin-token")) {
            nav("/auth");
        }

        async function fetchData() {
            const _users = await getUsers(localStorage.getItem("admin-token"));
            setUsers(_users.data.users);
        }

        fetchData();
    }, []);

    return (
        <>
            <div class="users">

                <Menu />

                <section className={"ms-5"}>
                    <div className={"flex justify-between mb-3 mt-3 text-white py-5 px-3 bg-gray-800 rounded-md"}>
                        <h1 className={"text-4xl font-extrabold leading-none"}>Пользователи</h1>
                    </div>
                    <div className={"bg-gray-800 rounded-md"}>
                        <table>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Ключ</th>
                                <th>Баланс</th>
                                <th>Активен до</th>
                                <th>Роль</th>
                                <th colSpan={2}>Забанен?</th>
                                <th>TAG</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((item) => {
                                return (
                                    <tr>
                                        <td>{item.id}</td>
                                        <td
                                            className={"cursor-pointer"}
                                            onClick={() => {
                                                setModalUser(item);
                                            }}
                                        >{item.token}</td>
                                        <td>{item.balance}</td>
                                        <td>{new Date(item.activeUntil).toDateString()}</td>
                                        <td>{item.role == 3 ? 'MOD' : 'USER'}</td>
                                        <td>{item.banned ? 'ДА' : 'НЕТ'}</td>
                                        <td>
                                            {item.banned ? (
                                                <FontAwesomeIcon icon={faCircleCheck}
                                                                 className={"text-green-600"}
                                                                 onClick={async ()=> {
                                                                     if (window.confirm('Are you sure you wish to UNBAN this user?'))
                                                                         await banUser(localStorage.getItem("admin-token"), item._id)
                                                                     const _users = await getUsers(localStorage.getItem("admin-token"));
                                                                     setUsers(_users.data.users);
                                                                 }}
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={faBan}
                                                                 className={"text-red-600"}
                                                                 onClick={async ()=> {
                                                                     if (window.confirm('Are you sure you wish to BAN this user?'))
                                                                         await banUser(localStorage.getItem("admin-token"), item._id)
                                                                     const _users = await getUsers(localStorage.getItem("admin-token"));
                                                                     setUsers(_users.data.users);
                                                                 }}
                                                />
                                            )

                                            }

                                        </td>
                                        <td className={"cursor-pointer"} onClick={async () => {
                                            const tag = prompt("Введите новый тег");
                                            if (tag && tag !== null) {
                                                const data = await updateTag(
                                                    localStorage.getItem("admin-token"),
                                                    item._id,
                                                    tag
                                                );
                                                setUsers(data.data.users);
                                            }
                                        }}>
                                            {item.tags}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <div
                            className="button"
                            onClick={async () => {
                                const days = prompt("Введите количество дней");
                                const tags = prompt("Введите TAGS");
                                if (days && days !== null) {
                                    const data = await newUser(
                                        localStorage.getItem("admin-token"),
                                        days,
                                        tags
                                    );
                                    setUsers(data.data.users);
                                }
                            }}
                        >Новый пользователь
                        </div>
                        <br/>
                        <div className="flex ">
                        <div
                            className="button"
                            onClick={async () => {
                                const text = prompt("Введите текст сообщения");
                                if (text && text !== null) {
                                    const ids = prompt("Перечислите ID через запятую (без пробелов)");
                                    if (ids && ids !== null) {
                                        const data = await sendMail(
                                            localStorage.getItem("admin-token"),
                                            text,
                                            ids.split(",")
                                        );
                                    }
                                }
                            }}
                        >Отправить уведомление
                        </div>
                        <div
                            className="button ms-3"
                            onClick={async () => {
                                const text = prompt("Введите текст сообщения");
                                if (text && text !== null) {
                                    const data = await sendMail(
                                        localStorage.getItem("admin-token"),
                                        text
                                    );
                                }
                            }}
                        >Уведомление всем
                        </div>
                        </div>
                        <br/>
                        <br/>
                        <div
                            className="button"
                            onClick={async () => {
                                const data = await newModer(
                                    localStorage.getItem("admin-token")
                                );
                                setUsers(data.data.users);
                            }}
                        >Создать модератора
                        </div>
                        <br/>
                    </div>
                </section>
            </div>
            {modalUser?.token && (
                <div class="user">
                    <div>
                        <h1>Пользователь</h1>
                        <img src={closeIcon} onClick={() => setModalUser(null)} alt="close"/>
                        <figure>
                            <figcaption>Баланс</figcaption>
                            <input
                                type="button"
                                value="Изменить"
                                onClick={async () => {
                                    const balance = prompt("Введите новый баланс");
                                    if (balance && balance !== null && Number(balance)) {
                                        const data = await changeBalance(
                                            localStorage.getItem("admin-token"),
                                            modalUser.token,
                                            balance
                                        );
                                        setUsers(data.data.users);
                                        setModalUser(null)
                                    }
                                }}
                            />
                        </figure>
                        <figure>
                            <figcaption>Продлить ключ</figcaption>
                            <input type="button" value="Продлить" onClick={async () => {
                                const days = prompt("Сколько дней добавить?");
                                if (days && days !== null && Number(days)) {
                                    const data = await addDays(
                                        localStorage.getItem("admin-token"),
                                        modalUser.token,
                                        days
                                    );
                                    setUsers(data.data.users);
                                    setModalUser(null)
                                }
                            }}/>
                        </figure>
                        <figure>
                            <figcaption>Удалить пользователя</figcaption>
                            <input type="button" value="Удалить" onClick={async () => {
                                const conf = window.confirm("Точно удалить?");
                                if (conf) {
                                    const data = await deleteUser(
                                        localStorage.getItem("admin-token"),
                                        modalUser.token
                                    );
                                    setUsers(data.data.users);
                                    setModalUser(null)
                                }
                            }}/>
                        </figure>
                    </div>
                </div>
            )}
        </>
    );
};

export default Users;
